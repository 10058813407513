.top-button {
  position: fixed;
  display: block;
  z-index: 1000;
  font-size: $font-size-base/1.3;
  font-weight: 700;
  //box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  //background-color: $color-ci;
  background-color: transparent;
  border-left: solid 15px lighten($color-ci,30%);
  border-top: solid 15px lighten($color-ci,30%);
  border-right: solid 15px transparent;
  border-bottom: solid 15px transparent;
  transform: rotate(45deg);
  color: white;
  cursor: pointer;
  padding: 0;
  right: $space-base*3;
  bottom: $space-base;
  margin-right: -200px;
  opacity: 0;
  transition: all .4s ease-in;

  &:hover {
    transform: scale(1.2) rotate(45deg);
  }

  &.scroll--down {
    margin-right: 0;
    opacity: 1;
    transition: all .4s ease-out;
  }
}

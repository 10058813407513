@mixin flexin($flex-direction: row, $justify-content: space-between, $align-items: stretch) {
  -js-display: flex;
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

$color-ci: #009ee0;
$color-gray: #4a4a4a;

$font-size-base: 1em;

// Screensizes
$screen-wide: 1440px;
$screen-large: 1280px;
$screen-medium: 1024px;
$screen-small: 768px;
$screen-mini: 320px;

$screen-desktop: $screen-wide;
$screen-tablet: $screen-medium;
$screen-mobile: $screen-small;

// Spaces
$space-base: 20px;

// Hamburger Variables
$hamburger-padding-x                       : 8px !default;
$hamburger-padding-y                       : 8px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 2px !default;
//$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $color-ci !default;
//$hamburger-layer-border-radius             : 4px !default;
//$hamburger-hover-opacity                   : 0.7 !default;
//$hamburger-hover-transition-duration       : 0.15s !default;
//$hamburger-hover-transition-timing-function: linear !default;
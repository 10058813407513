.entry {
  background: {
    color: $color-ci;
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  @include flexin();

  h1 {
    color: #fff;
    width: 50%;
    padding: 60px $space-base 60px 0;
  }

  img {
    position: absolute;
    width: 50%;
    right: 0;
    bottom: -35%;
  }
}

@media (max-width: $screen-tablet) {
  .entry {
    margin-bottom: 20%;
    @include flexin(column);

    h1 {
      width: 100%;
      padding: 0;
    }

    img {
      position: relative;
      width: 100%;
      right: auto;
      bottom: 0;
      margin-bottom: -20%;
    }
  }

}

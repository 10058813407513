.map {
  //width: 100%;
  //margin: 50px 0 3000px;
}
.map iframe{
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative; /* IE needs a position other than static */
}
.map iframe.clicked{
  pointer-events: auto;
}
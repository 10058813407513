.blue-grid {
  background-color: $color-ci;
  color: #fff;

  .container {
    padding: 6% 0;
  }

  h1 {
    margin: 0;
  }

  * {
    color: #fff;
  }

  a {
    opacity: 0.6;

    &:hover {
      opacity: 1;
      color: $color-ci;
    }
  }
}

.blue-grid__wrapper {
  @include flexin(row);
  padding: $space-base 0;
  //margin: 0 -$space-base;

  > * {
    padding: 0 $space-base;
  }
}

.blue-grid__wrapper__elem {
  &--6 {
    width: 100%;
  }
  &--12 {
    width: 100%;
  }
}

@media (max-width: $screen-mobile) {
  .blue-grid__wrapper {
    @include flexin(column);

    > * {
      //padding: 0 0;
    }
  }

  .blue-grid__wrapper__elem {
    &--6 {
      width: 100%;
    }
    &--12 {
      width: 100%;
    }
  }
}
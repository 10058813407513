.facts {

}

.facts__top {
  background: {
    color: $color-ci;
  }
  @include flexin();

  h1 {
    color: #fff;
    width: 50%;
    padding: 20px $space-base 20px 0;
  }

  img {
    position: absolute;
    width: 50%;
    right: 0;
    bottom: -240%;
    //max-width: 600px;
  }
}

.facts__bottom {
  .facts__bottom__content {
    width: 50%;
    padding-right: 5%;
    margin: 8% 0;

    h2 {
      color: $color-ci;
    }
  }
}

@media (max-width: $screen-tablet) {
  .facts__top {
    @include flexin(column);

    h1 {
      width: 100%;
      padding: 0;
    }

    img {
      position: relative;
      width: 100%;
      right: auto;
      bottom: 0;
      margin-bottom: -50%;
    }
  }

  .facts__bottom {
    margin-top: 50%;
    .facts__bottom__content {
      width: 100%;
      padding-right: 0;
    }
  }
}

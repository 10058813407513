.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: auto;
  height: 42vh;
  max-width: none;
  opacity: 0.3;
  transition: all 0.3s;

  img {
    max-height: 100%;
  }
}

.swiper-slide-active {
  opacity: 1;
  transition: all 0.3s;
}

.swiper-pagination-bullet-active {
  background: $color-ci;
}

html {
  font: {
    size: 100%;
  }
}

body {
  color: $color-gray;

  font: {
    family: 'Roboto', sans-serif;
    size: 1.3125rem;
    weight: 300;
  }

  line-height: 1.5;

  h1, h2, h3, h4, h5, h6 {
    color: $color-gray;
    line-height: 1.3;
  }

  @include font-smoothing();
}

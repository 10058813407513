.imagebox {
  margin: 6% 0;
}

.imagebox__images {
  @include flexin(row,stretch);
}

.imagebox__images__elem {
  width: 50%;

  img {
    width: 100%;
  }
}

@media (max-width: $screen-mobile) {
  .imagebox__images {
    @include flexin(column);
  }

  .imagebox__images__elem {
    width: 100%;
  }
}

.footer {
  .container {
    @include flexin();
  }
  ul {
    margin-top: 21px;

    li {
      float: none;

      a {
        padding: 0;
      }
    }
  }
}

@media (max-width: $screen-tablet) {
  .footer {
    .container {
      @include flexin(column);
    }
  }
}
ul.nav--default {
  padding:0;
  margin: $space-base/2 0 0 0;

  li {
    float: left;
    list-style: none;

    a {
      //color: $color-gray*2;
      text-decoration: none;
      display: block;
      padding: $space-base/2;
      transition: all 0.4s;

      &:hover {
        //color: $color-ci;
      }
    }

    &.active {
      a {
        color: $color-gray;
      }
    }
  }
}
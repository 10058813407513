*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  hyphens: auto;
}

body {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 700;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

a {
  color: lighten($color-gray,20%);
  text-decoration: none;
  transition: all .4s;
  cursor: pointer;

  &:hover {
    color: $color-ci;
  }
}

h1 {
  font-size: $font-size-base * 3;
  font-weight: 700;

  @media (max-width: $screen-mobile) {
    font-size: $font-size-base * 1.7;
  }
}

h2 {
  font-size: $font-size-base * 2;

  @media (max-width: $screen-mobile) {
    font-size: $font-size-base * 1.4;
  }
}

h3 {
  font-size: $font-size-base * 1.2;

  @media (max-width: $screen-mobile) {
  }
}

.tablet-hidden {
  @include max-screen($screen-tablet) {
    display: none;
  }
}

.mobile-hidden {
  @include max-screen($screen-mobile) {
    display: none;
  }
}

.section {
}

.container {
  position: relative;
  width: 100%;
  max-width: $screen-desktop;
  margin: 0 auto;
  padding: 20px;

}

.text-narrow {
  max-width: 50%;

  @media (max-width: $screen-tablet) {
    max-width: 100%;
  }
}

.map {
  height: 50vh;
}

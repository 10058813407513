.grid {
  h2 {
    width: 50%;
    padding-right: 5%;
  }
}

.grid-elems {
  @include flexin();
  margin: 0 -$space-base;
}

.grid-elems__elem {
  width: 100%;
  padding: $space-base;

  img {
    width: 120px;
    display: inline;
  }

  h3 {
    margin-bottom: 10px;
  }
}

@media (max-width: $screen-tablet) {
  .grid {
    h2 {
      width: 100%;
      padding-right: 0;
    }
  }
}

@media (max-width: $screen-mobile) {
  .grid-elems {
    @include flexin(column)
  }

  .grid-elems__elem {
    width: 100%;
  }
}

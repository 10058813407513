.header {
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 70px 0 rgba(0,0,0,0.1);
}

.header__top {
  color: $color-ci;
  text-align: right;

  .header__top__phone {
    padding: $space-base/2;

    * {
      display: inline-block;
      color: $color-ci;
    }
  }
}

.header__bottom {
  @include flexin();
}

.header__bottom__button {
  display: none;
  position: absolute;
  z-index: 1000;

  &:focus {
    outline: none;
  }
}

.header__bottom__logo {
  height: 70px;
  width: auto;
  display: block;
  position: relative;

  a {
    position: relative;
    display: inline-block;
    height: 100%;

    img {
      position: relative;
      height: 100%;
      max-width: inherit;
      width: auto;
      display: block;
    }
  }
}

.header__bottom__nav {
  ul {
    margin: $space-base/2 0 0 $space-base;
  }
}

@media (max-width: $screen-tablet) {
  .header__bottom {

  }

  .header__bottom__logo {
    height: 50px;
  }
}

@media (max-width: $screen-mobile) {
  .header {
    position: relative;
  }

  .header__top__phone {
    display: none;
  }

  .header__bottom {
    @include flexin(column);
  }

  .header__bottom__button {
    display: block;
    position: absolute;
  }

  .header__bottom__logo {
    text-align: center;
    height: 40px;
  }

  .header__bottom__nav {
    overflow: hidden;
    height: 0;

    ul {
      margin: 0;
      padding: 20px 0;
      text-align: center;

      li {
        float: none;

        a {
          color: $color-gray*2;
          text-decoration: none;
          display: block;
          padding: $space-base/2;
        }
      }
    }
  }
}

